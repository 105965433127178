import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import Ferrara02 from "@/images/ferrara/ferrara_02.jpg";
import {eventData} from '../data/event-list.js'
import moment from 'moment'



const CalendarListPage = () => {
    return (
      <MenuContextProvider>
        <Layout PageTitle="Event List">
          <HeaderOne />
          <PageHeader title="Event" image={Ferrara02} name="Event" />
          <div className="p-4">
          <ul className="list-unstyled">
              {eventData.map((data, key) =>(
                <li className="d-flex my-4" key={key}>
                    <img className="pr-4 align-self-center w-25 h-25" src={Ferrara02} alt={data.title}/>
                    <div style={{"paddingLeft":"20px"}} className="media-body">
                        <div className="flex flex-row gap-2 text-sm text-gray-500"> 
                          <span><i className="far fa-calendar" />  {data.start.toLocaleDateString()}</span>
                          <span><i className="far fa-clock" /> {moment("2013-03-07T07:00:00-08:00").format('h:mma')}</span>
                          <span><i className="far fa-location-arrow"/> 5100 Campuswood Drive, Syracuse, NY 13210</span>
                        </div>
                        <h5 className="mt-3 mb-3">{data.title}</h5>
                        <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>
                        <a href="localhost:8000" className=" mt-3 btn-yellow">Register Now</a>
                    </div>
              </li>
              ))}
            </ul>
          </div>
          <Footer />
        </Layout>
      </MenuContextProvider>
    );
  };
  
  export default CalendarListPage;
  